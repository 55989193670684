<template>
  <!-- 取消挽回 -->
  <div class="Cancelrecall" v-loading="loading">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">任务信息</div>
      <div class="flex_start">
        <div class="flex_start mr20">
          <el-button type="info" style="height: 40px" @click="godetail"
            >查看订单详情</el-button
          >
          <el-button
            v-if="wiahulema2"
            type="success"
            @click="waihu('dian')"
            style="height: 40px"
            >外呼{{ waihudian.username }}店长</el-button
          >
          <el-button v-else-if="!wiahulema2" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
          <el-button
            v-if="wiahulema"
            type="warning"
            @click="waihu"
            style="height: 40px"
            >外呼客户</el-button
          >
          <el-button v-else-if="!wiahulema" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
        </div>
        <div style="width: 53px">
          <span
            class="f13 mt30 Shrink"
            @click="Shrink(0)"
            style="color: #aba9a9"
            >{{ isindex[0].msg }}</span
          >
        </div>
      </div>
    </div>
    <!-- 信息内容 -->
    <div
      class="flex_column f14 bbe9s pb20 mt10"
      :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">客户姓名:</span>
              <span>{{ infos2.contacts }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">联系方式:</span>
              <span>{{ infos2.telephone }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">房屋问题:</span>
              <span v-if="infos2.problemTitle">{{ infos2.problemTitle }}</span>
              <span v-if="infos2.problemTitle1"
                >、{{ infos2.problemTitle1 }}</span
              >
              <span v-if="infos2.problemTitle2"
                >、{{ infos2.problemTitle }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">订单来源:</span>
              <span v-if="infos2.channelTitle != null"
                >{{ infos2.channel }}-{{ infos2.channelTitle }}</span
              >
              <span v-else>{{ infos2.channel }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">下单时间:</span>
              <span>{{ infos2.created_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start flex_items mt10">
              <span class="mr10 t1sp">家庭地址:</span>
              <div>{{ infos2.addres }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">添加微信:</span>
        <div style="width: 574px">
          <span v-if="infos2.wechat == 0">否</span>
          <span v-else>是</span>
        </div>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">备注信息:</span>
        <div style="width: 574px">{{ infos2.remarks }}</div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(1)" style="color: #aba9a9">{{
        isindex[1].msg
      }}</span>
    </div>
    <!-- 操作记录 -->
    <div class="flex_between pb10 mt10 bbe9s">
      <div class="title f-left p10">操作记录</div>
    </div>
    <!-- 内容 -->
    <div
      class="flex_column f14 bbe9s pb30"
      :style="{ display: isindex[1].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">接单店长:</span>
              <span>{{ infos2.username }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">订单状态:</span>
              <span>{{ infos2.states }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">上门时间:</span>
              <span>{{ infos2.planned }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">取消原因:</span>
              <span>{{ infos2.reason }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">取消时间:</span>
              <span>{{ infos2.tui_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">报价金额:</span>
              <span>{{ infos2.totalprice }}元</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 客服跟进记录 -->
      <div class="flex_column pb10 mt10 f16">
        <div class="title f-left p10">客服跟进记录</div>
        <div class="mt20 innerkuang">
          <el-table :data="infos2.through" border>
            <el-table-column prop="th_time" label="时间"></el-table-column>
            <el-table-column prop="mode" label="方式"></el-table-column>
            <el-table-column label="处理人">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.role == 1"
                    >{{ scope.row.username }}/客服</span
                  >
                  <span v-else>{{ scope.row.username }}/店长</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="是否接通">
              <template slot-scope="scope">
                <span v-if="scope.row.conversation == 0">是</span>
                <span v-if="scope.row.conversation == 2">否</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="下次跟进时间"
            ></el-table-column>
            <el-table-column prop="remar" label="沟通内容"></el-table-column>
            <el-table-column label="沟通录音">
              <template slot-scope="scope">
                <div v-if="scope.row.record.length == 0">暂无录音</div>
                <div v-else>
                  <audio
                    style="width: 100%; outline: none"
                    :src="scope.row.record[0].monitorfilename"
                    controls="controls"
                  ></audio>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(2)" style="color: #aba9a9">{{
        isindex[2].msg
      }}</span>
    </div>
    <!-- 任务操作-挽回 -->
    <div class="flex_between pb10 mt10 bbe9s">
      <div class="title f-left p10">任务操作-挽回</div>
    </div>
    <!-- 内容 -->
    <div
      class="flex_warp flex_items bbe9s f16 pb20"
      :style="{ display: isindex[2].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="flex_warp flex_items m-r-60">
        <div class="flex_start flex_items mt20 mr20">
          <span class="mr10 isok">下次跟进时间</span>
          <el-date-picker
            :clearable="true"
            v-model="isok.time1"
            type="datetime"
            placeholder="选择下次跟进时间"
          ></el-date-picker>
        </div>
        <div class="flex_start flex_items mt20 mr20">
          <span class="mr10 isok">客户电话是否接通</span>
          <el-radio-group v-model="isok.istong" class="flex_items f14">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="flex_warp flex_items">
          <div class="flex_start flex_items m-r-60 mt20">
            <span class="mr10 isok">订单是否挽回</span>
            <el-radio-group
              v-model="isok.wanhui"
              class="flex_items flex_start f14"
            >
              <el-radio
                :label="0"
                @click.native.prevent="clickitem('wanhui', 0)"
                >是</el-radio
              >
              <el-radio
                :label="2"
                @click.native.prevent="clickitem('wanhui', 2)"
                >否</el-radio
              >
            </el-radio-group>
          </div>
          <!-- 挽回原因 -->
          <div class="flex_start flex_items m-r-60 mt20">
            <span class="isok">挽回原因</span>
            <el-select
              v-model="isok.wanhuila"
              multiple
              collapse-tags
              placeholder="挽回原因"
              style="width: 282px"
            >
              <el-option
                v-for="item in wanhuiyuan"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <!-- 未挽回原因 -->
          <div class="flex_start flex_items m-r-60 mt20">
            <span class="isok">未挽回原因</span>
            <el-select
              v-model="isok.nowanhuila"
              multiple
              collapse-tags
              placeholder="未挽回原因"
              style="width: 282px"
            >
              <el-option
                v-for="item in nowanhui"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!-- 是否转派 -->
        <div class="flex_start flex_items mt20">
          <div class="flex_start flex_items m-r-60">
            <span class="mr10 isok">订单是否转派</span>
            <el-radio-group
              v-model="isok.zhuan"
              class="flex_items flex_start f14"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </div>
          <!-- 转派店长 -->
          <div v-if="isok.zhuan == 1" class="flex_start flex_items">
            <span class="isok" style="width: 82px">转派店长</span>
            <el-select
              class="mr10"
              filterable
              v-model="isok.shopper"
              collapse-tags
              placeholder="选择转派店长"
            >
              <el-option
                v-for="item in shoppers"
                :key="item.user_id"
                :label="item.usernamestorename"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 备注信息 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20" style="width: 173px">沟通备注</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入沟通备注信息"
          v-model="isok.textarea"
        ></el-input>
      </div>
    </div>
    <!-- 提交 -->
    <div>
      <el-button class="tijiao" type="primary" @click="tijiao"
        >提交处理结果</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentPage3"],
  data() {
    return {
      loading: true,
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
      ],
      infos2: {},
      isok: {
        istong: 0,
        wanhui: 0,
        zhuan: 1,
        time1: "",
        textarea: "",
        shopper: "",
        wanhuila: [],
        nowanhuila: [],
      },
      shoppers: [],
      wanhuiyuan: [
        "同意更换店长",
        "之前不打算做，现在要处理了",
        "介绍了优惠客户感兴趣",
        "之前店长拒绝上门",
        "店长单方面取消订单",
        "之前找的人/公司没解决问题",
        "有新的需求",
        "其它",
      ],
      nowanhui: [
        "联系不上业主（关机、无法接通、未接）",
        "价格超预期，不做了",
        "工期超预期，不做了",
        "嫌麻烦，没地方住",
        "对益鸟不满意",
        "暂时不处理了",
        "房东/装修公司/邻居/物业责任",
        "客户纯咨询",
        "做不了",
        "找熟人/朋友/自行处理",
        "其它",
      ],
      // 外呼
      wiahulema: true,
      wiahulema2: true,
      jige: "",
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      waihudian: { username: "", mobile: "" },
      isSaveState: true,
      ExternalData: "",
      call: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.$store.state.order_id,
          Colloquialism: 4,
          // order_id: "13426"
        })
        .then((res) => {
          if (res.code == 200) {
            this.infos2 = res.data;
            this.shop();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长
    shop() {
      this.util
        .get(this.HOST + "/Common/user", {
          order_id: this.$store.state.order_id,
          city_id: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            this.dianzhangphone();
            // this.loading = false;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长号码名字
    dianzhangphone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 跳转详情
    godetail() {
      this.util.godetail(this.infos2);
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    // 单选点击取消
    clickitem(kind, e) {
      if (kind == "wanhui") {
        this.isok.wanhui = this.util.cancleradio(kind, e, this.isok.wanhui);
      }
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
    tijiao() {
      let order_id = this.$store.state.order_id;
      let ttme = this.util.datatime(this.isok.time1);
      if (ttme == "NaN-NaN-NaN NaN:NaN:NaN") {
        ttme = "";
      }
      // if (
      //   this.isok.wanhui == 0 &&
      //   this.isok.wanhuila.length == 0 &&
      //   this.isok.istong == 0
      // ) {
      //   this.$message({
      //     type: "error",
      //     message: "请选择挽回原因"
      //   });
      //   return false;
      // }
      // if (
      //   this.isok.wanhui == 2 &&
      //   this.isok.nowanhuila.length == 0 &&
      //   this.isok.istong == 0
      // ) {
      //   this.$message({
      //     type: "error",
      //     message: "请选择不挽回原因!"
      //   });
      //   return false;
      // }
      if (this.isok.zhuan == 1 && this.isok.shopper == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择转派店长",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务处理中...",
      });
      this.util
        .post(this.HOST + "/Order/ProcessingTasks", {
          order_id: order_id,
          through_id: 0,
          type: 5,
          connect: this.isok.istong,
          remar: this.isok.textarea,
          end_time: this.util.datatime(this.isok.time1),
          cc_id: this.$store.state.cc_id,
          user_id: this.isok.shopper,
          detainment: this.isok.wanhui,
          Call_record: this.call,
          retention: JSON.stringify(this.isok.wanhuila)
            .replace(/\[|]/g, "")
            .replace(/\"/g, ""),
          reasons: JSON.stringify(this.isok.nowanhuila)
            .replace(/\[|]/g, "")
            .replace(/\"/g, ""),
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
